<template>
    <div>
        <el-scrollbar class="set-container">
            <div class="main-content">
                <div class="content">
                    <div class="form-content" v-if="showDetail">
                        <el-form :model="addForm" label-width="150px" :rules="rules" ref="addForm"
                                 class="demo-ruleForm">
                            <el-form-item label="用户名" prop="username">
                                <el-input v-model="addForm.username" :disabled="true"></el-input>
                            </el-form-item>
                            <el-form-item label="密码" prop="">
                                <el-input v-model.trim="addForm.password" show-password
                                          auto-complete="new-password" placeholder="密码"></el-input>
                            </el-form-item>
                            <el-form-item label="小邮局名称" prop="courier_name">
                                <el-input v-model="addForm.courier_name"></el-input>
                            </el-form-item>
                            <el-form-item label="省市区" prop="value">
                                <el-cascader v-model="addForm.value" :options="areaArr" :props=keyObj clearable
                                             @change="handleChange" style="width: 300px">
                                </el-cascader>
                            </el-form-item>
                            <el-form-item label="详细地址" prop="detail_area">
                                <el-input v-model="addForm.detail_area"></el-input>
                            </el-form-item>
                            <el-form-item class="footer-box">
                                <el-button type="primary" @click="submitForm('addForm')" class="left-btn">保存</el-button>
                                <el-button @click="resetForm">取消</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="form-content" v-else>
                        <div class="form-item">
                            <div class="item-title">用户名</div>
                            <div class="item-right">
                                <div class="item-value">{{addForm.username}}</div>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="item-title">小邮局名称</div>
                            <div class="item-right">
                                <div class="item-value">{{addForm.courier_name}}</div>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="item-title">地区</div>
                            <div class="item-right">
                                <div class="item-value">{{addForm.province}}/{{addForm.city}}/{{addForm.district}}</div>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="item-title">详细地址</div>
                            <div class="item-right">
                                <div class="item-value">{{addForm.detail_area}}</div>
                            </div>
                        </div>
                        <div class="form-item">
                            <el-button @click="viewDetail" type="primary">修改信息</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import {courier_shop_info, courier_shop_edit} from '@/config/apis'
    import {areaList} from '@/utils/area'

    export default {
        name: "StoreInfo",
        data() {
            return {
                showDetail: false,
                addForm: {
                    username: '',
                    password: '',
                    courier_name: '',
                    value: [],
                    province: '',
                    city: '',
                    district: '',
                    detail_area: '',
                },
                rules: {
                    courier_name: {required: true, message: '请输入邮局名称', trigger: 'blur'},
                    username: {required: true, message: '请输入账号', trigger: 'blur'},
                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                        {min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur'}
                    ],
                    value: {required: true, message: '请选择省市区', trigger: 'change'},
                    detail_area: {required: true, message: '请输入详细地址', trigger: 'blur'},
                },
                areaArr: [],
                keyObj: {value: 'areaName', label: 'areaName', children: 'children'},
            }
        },
        mounted() {
            this.getInfo()
            this.areaArr = areaList
        },
        methods: {
            getInfo() {
                courier_shop_info().then(res => {
                    this.addForm.username = res.data.username
                    this.addForm.courier_name = res.data.courier_name
                    this.addForm.detail_area = res.data.detail_area
                    this.addForm.province = res.data.province
                    this.addForm.city = res.data.city
                    this.addForm.district = res.data.district
                    this.addForm.value = [res.data.province, res.data.city]
                    if (res.data.district) {
                        this.addForm.value[2] = res.data.district
                    }
                })
            },
            handleChange(val) {
                if (val.length > 0) {
                    this.addForm.province = val[0]
                    this.addForm.city = val[1] ? val[1] : ''
                    this.addForm.district = val[2] ? val[2] : ''
                }
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData()
                        formData.append('courier_name', this.addForm.courier_name)
                        formData.append('province', this.addForm.province)
                        formData.append('city', this.addForm.city)
                        formData.append('detail_area', this.addForm.detail_area)
                        if (this.addForm.district) {
                            formData.append('district', this.addForm.district)
                        }
                        if (this.addForm.password) {
                            formData.append('password', this.addForm.password)
                        }
                        courier_shop_edit(formData).then((res) => {
                            this.$message.success(res.msg)
                            this.getInfo()
                            this.dialogVisible = false
                            this.resetForm()
                        }).catch((err) => {
                            console.error(err)
                        })
                    } else {
                        return false;
                    }
                });
            },
            resetForm() {
                this.showDetail = false
            },
            viewDetail() {
                this.showDetail = true
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .set-container {
        margin: 20px;
        background: #fff;
        height: calc(100% - 40px);
        border: 1px solid #e6e6e6;
        border-radius: 4px;

        & > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }

    .main-content {
        margin: 20px;
        background: #fff;
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
    }

    .tab {
        text-align: right;
    }

    .content {
        flex: 1;
        height: 1%;
        padding: 20px;
        display: flex;
        flex-direction: column;
    }

    .form-content {
        flex: 1;
        height: 1%;
        margin-top: 20px;
    }

    .el-input {
        width: 300px;
    }

    .footer-box {
        justify-content: start;
        display: flex;
    }

    .form-item {
        display: flex;
        height: 40px;
        margin-top: 20px;
        width: 450px;

        &:first-child {
            margin-top: 0;
        }

        .item-title {
            width: 150px;
            background: #F6F6F6;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #eee;
        }

        .item-right {
            height: 100%;
            flex: 1;
        }

        .item-value {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #eee;
        }
    }

</style>